<template>
    <div v-loading="loading" class="leaveDurationDetails">
        <div class="total">
            <div class="total-tip">将在出勤统计中记为</div>
            <div class="total-duration">共请假 {{ totalDuration }} 天</div>
        </div>
        <div class="details">
            <div v-for="(item, index) in details" :key="index" class="details-item">
                <div class="step">
                    <div class="step-circle"></div>
                    <div class="step-line"></div>
                </div>
                <div class="info">
                    <div class="info-row">
                        <span class="info-duration">{{ item.leaveDuration }}天</span>
                        <span class="info-date">{{ item.checkData + ' ' + item.week }}</span>
                    </div>
                    <div class="info-leave">
                        <div class="leave-progress">
                            <!-- 全天放假 -->
                            <div v-if="item.furloughType === '0'" class="leave-progress-bar leave-progress-all leave-progress-furlough">放假</div>
                            <!-- 全天请假 -->
                            <div v-else-if="item.leaveType === '0'" class="leave-progress-bar leave-progress-all leave-progress-leave">全天</div>
                            <div v-else class="leave-progress-row">
                                <!-- 下午工作 -->
                                <div v-if="item.furloughType !== '2' && item.leaveType !== '2'" class="leave-progress-work"></div>
                                <!-- 上午放假 -->
                                <div v-if="item.furloughType === '1'" class="leave-progress-bar leave-progress-half leave-progress-half_morning leave-progress-furlough">放假</div>
                                <!-- 上午请假 -->
                                <div v-else-if="item.leaveType === '1'" class="leave-progress-bar leave-progress-half leave-progress-half_morning leave-progress-leave">半天</div>
                                <!-- 上午工作 -->
                                <div v-else class="leave-progress-bar leave-progress-half leave-progress-work"></div>
                                <!-- 下午放假 -->
                                <div v-if="item.furloughType === '2'" class="leave-progress-bar leave-progress-half leave-progress-half_afternoon leave-progress-furlough">放假</div>
                                <!-- 下午请假 -->
                                <div v-else-if="item.leaveType === '2'" class="leave-progress-bar leave-progress-half leave-progress-half_afternoon leave-progress-leave">半天</div>
                            </div>
                            <div class="leave-progress-yellow"></div>
                        </div>
                        <div class="leave-times">
                            <span class="leave-time">{{ item.checkTimeStart }}</span>
                            <span class="leave-time">{{ item.checkTimeEnd }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn" @click="handleClose">确定</div>
    </div>
</template>

<script>
import { CampusOAModel } from "@/models/CampusOA.js";

export default {
    name: 'LeaveDurationDetails',
    components: {},
    props: {
        params: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            loading: false,
            totalDuration: '',
            details: []
        }
    },
    computed: {},
    watch: {},
    created() {
        this.init()
    },
    mounted() {},
    methods: {
        init() {
            this.getLeaveDurationDetails()
        },
        getLeaveDurationDetails() {
            this.loading = true
            const campusOAModel = new CampusOAModel()
            campusOAModel.getLeaveDurationDetails(this.params).then((res) => {
                console.log("[getLeaveDurationDetails]res:", res)
                if (res.data.code == "200") {
                    this.totalDuration = res.data.data.reduce((p, c) => { return p + c.leaveDuration }, 0)
                    this.details = res.data.data
                    console.log("[getLeaveDurationDetails]this.totalDuration:", this.totalDuration, "this.details:", this.details)
                    this.loading = false
                } else {
                    let errorMsg = "获取明细失败，请重试"
                    if (res.data.msg) errorMsg = res.data.msg
                    this.$message.error(errorMsg)
                }
            })
        },
        handleClose() {
            this.$emit('close');
        }
    }
}
</script>

<style lang="scss" scoped>
.leaveDurationDetails {
    margin: -16px -40px 0 -24px;
}

.total {
    width: 552px;
    height: 64px;
    background: #3C7FFF;
    border-radius: 8px;
    margin-right: 16px;
    padding: 0 27px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .total-tip {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 1;
    }

    .total-duration {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 1;
        margin-top: 8px;
    }
}

.details {
    max-height: 410px;
    overflow: auto;
    margin-top: 20px;
    padding-right: 16px;

    .details-item {
        display: flex;
        flex-direction: row;

        .step {
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            align-items: center;

            .step-circle {
                width: 12px;
                height: 12px;
                border: 2px solid #3C7FFF;
                box-sizing: border-box;
                border-radius: 50%;
                flex-shrink: 0;
            }

            .step-line {
                flex: 1;
                width: 1px;
                background-color: #E1E3E6;
            }
        }

        .info {
            flex: 1;
            margin-left: 12px;
            margin-bottom: 36px;

            .info-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: -2px;

                .info-duration {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #3C7FFF;
                    line-height: 18px;
                }

                .info-date {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #6D6F73;
                    line-height: 18px;
                    border-left: 1px solid #6D6F73;
                    padding-left: 7px;
                    margin-left: 8px;
                }
            }

            .info-leave {
                .leave-progress {
                    margin-top: 10px;

                    .leave-progress-bar {
                        height: 24px;
                        line-height: 19px;
                        text-align: center;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #2B2E33;
                    }
                    // 全天放假或请假
                    .leave-progress-all {
                        width: 100%;
                        border-radius: 6px;
                    }
                    // 放假
                    .leave-progress-furlough {
                        background: #ECFDD1;
                    }
                    // 请假
                    .leave-progress-leave {
                        background: #D1EFFC;
                    }
                    .leave-progress-row {
                        display: flex;
                        flex-direction: row;
                    }
                    // 半天放假或请假
                    .leave-progress-half {
                        width: 50%;
                    }
                    // 工作
                    .leave-progress-work {
                        background: transparent;
                    }
                    // 上午放假或请假
                    .leave-progress-half_morning {
                        border-radius: 6px 0 0 6px;
                    }
                    // 下午放假或请假
                    .leave-progress-half_afternoon {
                        border-radius: 0 6px 6px 0;
                    }
                    // 上午工作下午放假 上午工作下午请假 上午放假下午工作 上午请假下午工作
                    .leave-progress-work + .leave-progress-furlough,
                    .leave-progress-work + .leave-progress-leave {
                        border-radius: 6px;
                    }

                    .leave-progress-yellow {
                        position: relative;
                        z-index: 2;
                        width: 100%;
                        height: 6px;
                        background: #FACD91;
                        border-radius: 3px;
                        margin-top: -6px;
                    }
                }

                .leave-times {
                    margin-top: 6px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    overflow: hidden;

                    .leave-time {
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #6D6F73;
                    }
                }
            }
        }

        &:last-child {
            .info {
                margin-bottom: 0;
            }
        }
    }
}

.btn {
    width: 64px;
    height: 32px;
    margin: 20px 16px 0 auto;
    background: #2E71E6;
    border-radius: 4px;
    line-height: 32px;
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
}
</style>
