<template>
    <div>
        <tabbed-page
            v-show='showTab'    
            :tabList="tabList"
            @changeTabs="handlerChangeTabs"
        >
        </tabbed-page>
        <component
            :is="comList[idx]"
            :ruleForm="ruleForm"
            :rules="rules"
            @backWard="backWard"
            @setPageInitTabs="setPageInitTabs"
            @goAssetMaintenance="goAssetMaintenance"
            @backList="backList"
        ></component>
    </div>
</template>

<script>
import { CoworkingCampusOAVacationManagementModel } from '@/models/CoworkingCampusOAVacationManagement.js';
// import CoworkingCampusOAVacationManagementView from './CoworkingCampusOAVacationManagement/index.vue';
// import CoworkingCampusOAVacationStudentManagementView from './CoworkingCampusOAVacationManagement/student.vue';
// import CoworkingCampusOAVacationManagementNewPage from './CoworkingCampusOAVacationManagement/addPage.vue'
import TabbedPage from './Sub/TabbedPage/index.vue'
import { mapState } from 'vuex';
import { tabPageHasAuth } from '@/libs/tabbedPageAuth.js';
export default {
    name: "CoworkingCampusOAVacationManagementScrollWrapper",
    components: {
        TabbedPage,
        // CoworkingCampusOAVacationManagementView,
        // CoworkingCampusOAVacationManagementNewPage,
        // CoworkingCampusOAVacationStudentManagementView,
        CoworkingCampusOAVacationStudentManagementView : () => import(/* webpackChunkName:'CoworkingCampusOAVacationStudentManagementView' */"@/components/scrollWrapper/CoworkingCampusOAVacationManagement/student.vue"),
        CoworkingCampusOAVacationManagementView : () => import(/* webpackChunkName:'CoworkingCampusOAVacationManagementView' */"@/components/scrollWrapper/CoworkingCampusOAVacationManagement/index.vue"),
        CoworkingCampusOAVacationManagementHolidaysDetail : () => import(/* webpackChunkName:'CoworkingCampusOAVacationManagementHolidaysDetail' */"@/components/scrollWrapper/CoworkingCampusOAVacationManagement/holidaysDetail.vue"),
    },
    data () {
        return {
			showTab: true,
            idx: 0,
            comList: ['CoworkingCampusOAVacationStudentManagementView',
            'CoworkingCampusOAVacationManagementView',
            'CoworkingCampusOAVacationManagementHolidaysDetail'],
            tabList: [
                {
                    id: 0,
                    title: "学生假期",
                    com: "CoworkingCampusOAVacationStudentManagementView",
                    sel: true,
                    vHasPermi: ['studentVacation:all'],
                },
                {
                    id: 1,
                    title: "员工假期",
                    com: "CoworkingCampusOAVacationManagementView",
                    sel: false,
                    vHasPermi: ['teacherVacation:all'],
                },
            ],
            ruleForm: {
                id: '',
                schoolId: this.schoolId,
                vacationName: '',
                leaveUnit: '',
                durationCalculation: '',
                isNorm: '1',
                distributionRules: {}
            },
            rules: {
                vacationName: [
                    { required: true, message: '请输入假期名称', trigger: 'blur' }
                ],
                leaveUnit: [
                    { required: true, message: '请选择请假单位', trigger: 'change' }
                ],
                durationCalculation: [
                    { required: true, message: '请选择单位时长', trigger: 'change' }
                ]
            },
            height: '',
            // comp:'CoworkingCampusOAVacationStudentManagementView'
        }
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId
        }),
        // comp () {
        //     console.log('---',this.comList,this.idx)
        //     return this.comList[this.idx]
        // }
    },
    created(){
        console.log('是否进入')
        this.tabList = tabPageHasAuth(this.tabList);
        this.idx = this.tabList[0].id || 0;
        this.setQueryTab()
        console.log(this.comList, this.tabList, 'this.tabList')
    },
    activated() {
        this.setQueryTab()
    },
    mounted () {
    },
    methods: {
        setQueryTab() {
            const query = { ...this.$route.query };
            const id = this.$route.query.id
            if (id) {
                delete query.id;
                this.$router.replace({ query })
                this.handlerChangeTabs({ id: id })
            }
        },
        /**
         * @Description: 点击返回按钮
         * @DoWhat: 点击返回按钮
         * @UseScenarios: 点击返回按钮
         * @Attention: 点击返回按钮
         * @Author: 武东超
         * @Date: 2023-03-20 14:34:51
         */
        backWard () {
            // this.idx = 0;
            // this.comp = 'CoworkingCampusOAVacationManagementView'
        },
         setPageInitTabs(){
            // this.$eventDispatch('setGlobalPageType', 'list')
            // this.idx = 0;
        },
        handlerChangeTabs(data) {
            this.tabList.forEach((item, index) => {
                if (item.id === data.id) {
                    this.$set(item, 'sel', true)
                    this.idx = index;
                } else {
                    this.$set(item, 'sel', false)
                }
            })
        },
        goAssetMaintenance () {
            this.showTab = false;
            this.idx = 2;
            console.log('this.comList',this.comList[this.idx]);
            this.setGlobalPageType('detail')
        },
        backList () {
            this.showTab = true;
            this.idx = 1;
            this.setGlobalPageType('list')
        },
        setGlobalPageType (type) {
            this.type = type
            this.$eventDispatch('setGlobalPageType', type)
        },
    }

}
</script>
<style scoped lang="scss">
.content-main {
    margin-top: 0 !important;
}
</style>
